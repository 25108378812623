import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Div, Container, Row, Text } from 'atomize-jnh'
import logo from '../assets/logo-white.svg'

function funnelInterface (url, opt) {
  switch (url) {
    default: {
      break
    }
    case '?id=prices': {
      window.location = 'https://app.orionx.com/prices'
      break
    }
    case '?id=exchange': {
      if (opt) {
        window.location = `https://app.orionx.com/exchange/${opt}`
      } else {
        window.location = 'https://app.orionx.com/exchange'
      }

      break
    }
    case '?id=launchpad': {
      window.location = 'https://app.orionx.com/launchpad'
      break
    }
    case '?id=support': {
      window.location = 'https://orionx.crisp.help/'
      break
    }

    case '?id=privacy': {
      window.location = 'https://app.orionx.com/privacy-policy'
      break
    }

    case '?id=security': {
      window.location = 'https://app.orionx.com/security'
      break
    }
    case '?id=rates': {
      window.location = 'https://app.orionx.com/rates'
      break
    }
    case '?id=login': {
      window.location = 'https://app.orionx.com/login'
      break
    }
    case '?id=register': {
      if (opt) {
        window.location = `https://app.orionx.com/register?email=${opt}`
      } else {
        window.location = 'https://app.orionx.com/register'
      }
      break
    }
    case '?id=account': {
      window.location = 'https://app.orionx.com/accounts'
      break
    }
    case '?id=verify': {
      window.location = 'https://app.orionx.com/settings-verification'
      break
    }
    case '?id=discord': {
      window.location = 'https://discord.gg/6SXtXWZ'
      break
    }
    case '?id=instagram': {
      window.location = 'https://www.instagram.com/orionx.crypto/'
      break
    }
    case '?id=twitter': {
      window.location = 'https://twitter.com/orionx'
      break
    }
    case '?id=github': {
      window.location = 'https://github.com/orionx-dev/'
      break
    }
    case '?id=facebook': {
      window.location = 'https://www.facebook.com/orionx.crypto/'
      break
    }
  }
}

var parseQueryString = function (str) {
  var objURL = {}
  str.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), function ($0, $1, $2, $3) {
    objURL[$1] = $3
  })
  return objURL
}

const IndexPage = props => {
  useEffect(() => {
    const queryObj = parseQueryString(props.location.search)
    if (queryObj.email != null) {
      funnelInterface('?id=register', queryObj.email)
    } else if (queryObj.code != null) {
      funnelInterface('?id=exchange', queryObj.code)
    } else {
      funnelInterface(props.location.search)
    }
  }, [])

  return (
    <Div h="100vh" bg="rgb(20, 120, 246)">
      <Container>
        <Row>
          <Col>
            <Div m={{ t: '40vh' }}>
              <Div
                bgImg={logo}
                bgRepeat="no-repeat"
                cursor="pointer"
                w={{ xs: '9.5rem', md: '10rem' }}
                h={{ xs: '60px', md: '2.7rem' }}
                m="0 auto"
                bgSize={{ xs: 'contain', md: 'contain' }}
              />
              <Text textAlign="center" m={{ t: '12px' }} textColor="white">
                Te estamos redireccionando de forma segura
              </Text>
            </Div>
          </Col>
        </Row>
      </Container>
    </Div>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object
}

export default IndexPage
